import React, { Component } from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import Beatboxing from "./beatboxing/App";
import Rap from './rap/App';
import Landing from "./app"
export const Bug = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/beatboxing" element={<Beatboxing/>}/> 
                <Route path="/rap" element={<Rap/>} />              
            </Routes>
            </div>
    )
}
