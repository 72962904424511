import React from "react";
// // import { images } from "../../constants";
// import { data } from "../../constants";
import "./Contact.css";
import "../../constants/styles.css";

const Contact = ({ data }) => (
  <div className="app__contact app__bg section__padding" id="contact">
    <div style={{ position: "relative" }}>
      <h1
        className="headtext__cormorant"
        style={{ textAlign: "center", marginTop: "3vh" }}
      >
        Contact Us
      </h1>
    </div>
    <div className="row align-content-center justify-content-center row-cols-md-3 row-cols-sm-3 row-cols-2 row-cols">
      {data.map((props) => (
        <div className="col">
          <div className="our_team">
            <div className="picture_contact">
              <img
                className="img_contact"
                src={props.img.url}
                alt="profile_pic"
              />
            </div>
            <div className="team_content">
              <h3 className="h3_contact">{props.name}</h3>
              <h4 className="h4_contact">Organizer, Battle Underground</h4>
              <h4 className="h4_contact">+91 {props.text1}</h4>
            </div>
            <ul className="ul_contact">
              <li className="li_contact">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.text2}
                  className="fa fa-instagram icon__links-contact"
                  aria-hidden="true"
                >
                  {" "}
                </a>
              </li>
              <li className="li_contact">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto:${props.text3}`}
                  className="fa fa-envelope icon__links-contact"
                  aria-hidden="true"
                >
                  {" "}
                </a>
              </li>
              <li className="li_contact">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.text4}
                  className="fa fa-linkedin icon__links-contact"
                  aria-hidden="true"
                >
                  {" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Contact;
