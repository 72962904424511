import React from "react";
import Fade from "react-reveal/Fade";
import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div className="app__aboutus section__padding" id="about">
    <Fade bottom>
      <h1 className="headtext__cormorant">About Us</h1>
    </Fade>
    <div className="app__wrapper app__aboutus__inner">
      <div id="aboutUs__image" className=" aboutUs__image app__wrapper_img">
        <Fade bottom>
          <img
            className="synchro__frame_1"
            src={images.home}
            alt="header_img"
          />
        </Fade>
      </div>
      <div className="app__wrapper_info">
        <div className="app__aboutus-content_about">
          <Fade bottom>
            <p className="p__opensans" id="aboutus__p">
            The rap culture in India is currently surfing on a gigantic wave of popularity. In a short span of time, it has coursed from being unexplored to full of variety and rawness. With the diversity of languages that our nation holds, comes diversity in rappers and their performing styles. The emergence of numerous independent underground rappers ruling the streets is a testament to the budding interest in this arena of word battles. So hold your breaths to rise from the hustle as Antaragni’21 presents you yet another edition of Battle rap. The ROADTRIPS were held last year in 9 major cities including Delhi, Mumbai and Chandigarh with participant entries with diverse rapping styles. This year considering the current scenario, BATTLE RAP is coming to your city with its first 6 preliminary rounds being organised in an all new online mode. The winners from the preliminary rounds will get the opportunity to go head to head at the finale at IIT Kanpur and win incredible prizes. So get ready to spit your bars,  throw your punches and diss your opponents to silence, because this is your chance. Sign up now
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </div>
);
export default AboutUs;
