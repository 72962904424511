import images from "./images";

const schedule = [
  /*{
    img: images.kolkata,
    city: "All India Prelims",
    date: "Sunday, Jul 31",
    result: "To be announced",
  },*/
  {
    img: images.chandigarh,
    city: "Chandigarh",
    date: "31st July",
    result: "Psykick Tunes",
  },
  {
    img: images.bhopal,
    city: "Bhopal",
    date: "14th August",
    result: "Toshib",
  },
  {
    img: images.kolkata,
    city: "Kolkata",
    date: "9th September",
    result: "Ishan Saha",
  },
  {
    img: images.nagpur,
    city: "Nagpur",
    date: "18th December",
    result: "Kedar Beats",
  },

  {
    img: images.pune,
    city: "Pune",
    date: "4th February",
    result: "To be announced",
  },
  {
    img: images.varanasi,
    city: "varanasi",
    date: "To be announced",
    result: "To be announced",
  },
  {
    img: images.delhi,
    city: "Delhi",
    date: "To be announced",
    result: "To be announced",
  },
];

const gallery = [
  {
    img: images.gallery01,
  },
  {
    img: images.gallery02,
  },
  {
    img: images.gallery03,
  },
  {
    img: images.gallery04,
  },
  {
    img: images.gallery05,
  },
  {
    img: images.gallery06,
  },
  {
    img: images.gallery07,
  },
  {
    img: images.gallery08,
  },
];

const contactInfo = [
  {
    id: 1,
    name: "Abhishek Bansod",
    post: "Organizer, Battle Underground",
    image:
      "https://drive.google.com/uc?export=view&id=11FyBdmNd8o3s49j7_VRXoPKYhjsMRBG3",
    facebook: "https://www.facebook.com/abhishek.bansod.980?mibextid=ZbWKwL",
    phone: "7745840475",
    email: "underground@antaragni.in",
    linkedin: "https://www.linkedin.com/in/bansodabhishek",
  },
  {
    id: 2,
    name: "Ishaan Shukla",
    post: "Organizer, Battle Underground",
    image:
      "https://drive.google.com/uc?export=view&id=1tufHssN7_uNPakyMIUYDu_Jfi9QrAPmA",
    facebook: "https://www.facebook.com/ishaan.shukla.1426/",
    phone: "6376735516",
    email: "underground@antaragni.in",
    linkedin: "https://www.linkedin.com/in/ishaan-shukla-2b92a01b9/",
  },
  // {
  //   id: 3,
  //   name: "Akshat",
  //   post: "Organizer, Battle Underground",
  //   image:
  //     "https://drive.google.com/uc?export=view&id=19Ru_kCFkXlh5oHXu93JejXEbW6Ulg5Eo",
  //   facebook: "/",
  //   phone: "6376540560",
  //   email: "underground@antaragni.in",
  //   linkedin: "/",
  // },
];

const sponsorData = [
  {
    name: "Casa Decor",
    link: "https://www.instagram.com/casadecorindia/?hl=en",
    img: images.decor,
    post: "Powered By, Delhi Finale",
  },
  {
    name: "Euphonious studios",
    link: "https://www.instagram.com/theeuphoniousstudios/",
    img: images.euphonious,
    post: "Dream on Partner, Delhi Finale",
  },
  {
    name: "Ugta Bharat",
    link: "https://www.ugtabharat.com/",
    img: images.ugta,
    post: "Media Partner, Delhi Finale",
  },
  {
    name: "DM Digitals",
    link: "https://dmdigitals.com/",
    img: images.dmd,
    post: "Coverage Partner",
  },
  {
    name: "Dainik vishwamitra",
    link: "https://dainikvishwamitra.com/",
    img: images.Dv,
    post: "Media Partner",
  },
  {
    name: "Five Mad Men",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.fivemadmen,
    post: "Venue Partner",
  },
  {
    name: "Hyderabad Trend",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.ht,
    post: "Media Partner Hyderabad",
  },
  {
    name: "JMR Music Studio",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.jmr,
    post: "Dream On Partner",
  },
  {
    name: "Kolkata Nightlife",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.knl,
    post: "Online Media Partner",
  },
  {
    name: "Patsav",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.patsavv,
    post: "Associate Premium Partner Hyderabad",
  },
  {
    name: "Rap Club Delhi",
    link: "https://www.instagram.com/rap_club_delhi/",
    img: images.rcd,
    post: "Online Media Partner, Delhi Finale",
  },
  {
    name: "Plague Club And Longue",
    link: "https://www.instagram.com/playgueclublounge/",
    img: images.pcl,
    post: "Venue Partner, Delhi Finale",
  },
  {
    name: "SKYHY live",
    link: "https://www.instagram.com/antaragni.iitkanpur/",
    img: images.shlive,
    post: "Venue Partner Hyderabad",
  },
];

const pastSponsors = [
  {
    name: "Chennai Night Life",
    img: images.cnl,
    link: "https://www.facebook.com/chennainightlife.in/",
    post: "Media Partner, Chennai",
  },
  {
    name: " Raag to Rock",
    img: images.rtr,
    link: "https://www.facebook.com/RagatoRock/",
    post: " Media Partner, Kolkata",
  },
];

const data = {
  schedule,
  gallery,
  contactInfo,
  sponsorData,
  pastSponsors,
};

export default data;
