import React from "react";

// import { data } from "../../constants";
import { Fade } from "react-reveal";
import "./Schedule.css";

const Schedule = ({ data }) => (
  <div
    className="app_schedule app__wrapper section__padding"
    style={{ overflow: "hidden" }}
    id="schedule"
  >
    <Fade bottom>
      <h1 className="headtext__cormorant">Schedule</h1>
    </Fade>
    {data.length <= 0 ? (
      <h1 style={{ color: "white" }}>To be announced ...</h1>
    ) : (
      data.map((events) => (
        <div className="schedule__block">
          <div
            className="schedule__bgImg"
            style={{ backgroundImage: `url("${events.img.url}")` }}
          ></div>

          <div className="schedule__place">
            <p className="schedule__city">{events.name}</p>
          </div>
          <div className="schedule__content">
            <h1 className="schedule__date">{events.text1}</h1>
            <p className="schedule__result">Result: {events.text2}</p>
          </div>
        </div>
      ))
    )}
  </div>
);

export default Schedule;
