import React from "react";
import Fade from "react-reveal/Fade";
import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div className="app__aboutus section__padding" id="about">
    <Fade bottom>
      <h1 className="headtext__cormorant">About Us</h1>
    </Fade>
    <div className="app__wrapper app__aboutus__inner">
      <div id="aboutUs__image" className=" aboutUs__image app__wrapper_img">
        <Fade bottom>
          <img
            className="synchro__frame_1"
            src={images.home}
            alt="header_img"
          />
        </Fade>
      </div>
      <div className="app__wrapper_info">
        <div className="app__aboutus-content_about">
          <Fade bottom>
            <p className="p__opensans" id="aboutus__p">
              With all this new and exciting things going around, Antaragni
              brings to you a brand new edition in its arsenal, BEATBOXING.
              Beatboxing is among the youngest of art forms and we believe in
              providing a premium stage to artists in a zeal to express, perform
              and put themselves out there. Antaragni believes in uplifting the
              underground art and presenting it to the mainstream. As a budding
              artist, you have the chance to not only get your talent recognised
              at a national level but also win exciting prizes. Get ready to
              control the hearts of your audience with your rhythm and the
              audacity&nbsp; of your beats.&nbsp; Keeping in mind the current
              circumstances the first 6 road trips will be organised in an all
              new online mode. So stay tuned, for we are arriving at your city
              with a competition sequence where the winners from all across the
              nation get a chance to battle off in front of a crowd of thousands
              and a stellar panel of judges at IIT Kanpur.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </div>
);
export default AboutUs;
