import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Router} from 'react-router-dom';
import { Bug } from "./route";
import "./beatboxing/index.css";
import App from "./beatboxing/App";
const root = createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
    <Bug />
</BrowserRouter>
)
