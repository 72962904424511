import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
// import { images } from "./beatboxing/constants";
const Landing = () => {
  return (
    <div className="main-wrapper">
      {/* <header>
        <nav>
          <ul>
            <li>
              <a href="/">
                <img className="img-nav" src={images.fb} alt="fb"></img>
              </a>
            </li>
            <li>
              <a href="/">
                <img
                  className="img-nav"
                  src={images.linkedin}
                  alt="linkedin"
                ></img>
              </a>
            </li>
          </ul>
        </nav>
      </header> */}
      <div>
        <h2 className="logo">Battle Underground</h2>
      </div>
      <div className="box-wrapper">
        <div
          className="box1"
          style={{
            // "background-image": "url(/beatBoxing.jpg)",
            "backgroundPosition": "center center",
            "backgroundSize": "cover",
          }}
        >
          <Link to="/beatBoxing">
            <div className="photo"></div>
          </Link>
        </div>
        <div
          className="box2"
          style={{
            // "background-image": "url(./beatBoxing.jpg)",
            "backgroundPosition": "center center",
            "backgroundSize": "cover",
          }}
        >
          <Link to="/rap">
            <div className="photo"></div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Landing;
