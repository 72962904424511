import bg from "../assets/bg.jpg";
import beatbox from "../assets/typeface.png";
import insta from "../assets/insta.png";
import fb from "../assets/fb.png";
import kolkata from "../assets/kolkata.jpg";
import bhopal from "../assets/bhopal.jpeg";
import nagpur from "../assets/nagpur.jpeg"
import vadodara from "../assets/vadodara.jpg";
import hyderabad from "../assets/hyderabad.jpg";
import chennai from "../assets/chennai.jpg";
import chandigarh from "../assets/chandigarh.jpeg";
import delhi from "../assets/delhi.jpg";
import shillong from "../assets/shillong.jpg";
import gallery01 from "../assets/gallery/rap1.jpg";
import gallery02 from "../assets/gallery/rap2.jpg";
import gallery03 from "../assets/gallery/rap3.jpg";
import gallery04 from "../assets/gallery/rap4.jpg";
import gallery05 from "../assets/gallery/rap5.jpg";
import home from "../assets/gallery/rap1.jpg";
import decor from "../assets/sponsors/decor.png";
import euphonious from "../assets/sponsors/euphonious.png";
import ugta from "../assets/sponsors/ugta.png";
import dreamart from "../assets/gallery/rap1.jpg";
import rcd from "../assets/sponsors/Rapclubdelhi.png";
import poison from "../assets/sponsors/PoisonClub.jpg";
import pcl from "../assets/sponsors/Plagueclub.png"
import cnl from "../assets/sponsors/cnl.png";
import rtr from "../assets/sponsors/rtr.png";

const images = {
  bg,
  beatbox,
  insta,
  fb,
  kolkata,
  bhopal,
  nagpur,
  vadodara,
  hyderabad,
  chennai,
  chandigarh,
  delhi,
  shillong,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  home,
  decor,
  euphonious,
  ugta,
  dreamart,
  pcl,
  poison,
  rcd,
  cnl,
  rtr,
};

export default images;
