import React from "react";
// import { data } from "../../constants";
import { Fade } from "react-reveal";
import "./Sponsors.css";
import "../../constants/styles.css";

const Sponsors = ({ data }) => (
  <div className="app__wrapper" id="sponsors">
    <div className="app__sponsors  section__padding ">
      <Fade bottom>
        <h1 className="headtext__cormorant h1_sponsors">Past Sponsors</h1>
      </Fade>
      <Fade bottom cascade>
        <div className="row align-content-center justify-content-center row-cols-md-3 row-cols-2 row_sponsors">
          {data.length <= 0 ? (
            <h1 style={{ color: "white" }}>To be announced ...</h1>
          ) : (
            data.map((sponsor) => {
              return (
                <div className="col imageDiv_sponsors">
                  <a target="_blank" rel="noreferrer" href={sponsor.text1}>
                    <img
                      className="img_sponsors"
                      src={sponsor.img.url}
                      alt={sponsor.name}
                    />
                  </a>
                </div>
              );
            })
          )}
        </div>
      </Fade>
      {/* <Fade bottom>
        <h1 className="headtext__cormorant h1_sponsors">Past Sponsors</h1>
      </Fade>
      <Fade bottom cascade>
        <div className="row align-content-center justify-content-center row-cols-md-3 row-cols-2 row_sponsors">
          {data.pastSponsors.map((sponsor) => {
            return (
              <div className="col imageDiv_sponsors">
                <a target="_blank" rel="noreferrer" href={sponsor.link}>
                  <img
                    className="img_sponsors"
                    src={sponsor.img}
                    alt={sponsor.name}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </Fade> */}
    </div>
  </div>
);

export default Sponsors;
