import bg from "../assets/bg.jpg";
import beatbox from "../assets/typeface.png";
import insta from "../assets/insta.png";
import fb from "../assets/fb.png";
import linkedin from "../assets/linkedin.png";
import kolkata from "../assets/kolkata.jpg";
import bhopal from "../assets/bhopal.jpeg";
import nagpur from "../assets/nagpur.jpeg";
import vadodara from "../assets/vadodara.jpg";
import hyderabad from "../assets/hyderabad.jpg";
import chennai from "../assets/chennai.jpg";
import chandigarh from "../assets/chandigarh.jpeg";
import delhi from "../assets/delhi.jpg";
import varanasi from "../assets/varanasi.jpg";
import pune from "../assets/pune.jpeg";
import shillong from "../assets/shillong.jpg";
import gallery01 from "../assets/gallery/bx1.jpeg";
import gallery02 from "../assets/gallery/bx2.jpeg";
import gallery03 from "../assets/gallery/bx3.jpeg";
import gallery04 from "../assets/gallery/bx4.jpeg";
import gallery05 from "../assets/gallery/bx5.jpeg";
import gallery06 from "../assets/gallery/bx6.jpeg";
import gallery07 from "../assets/gallery/bx7.jpeg";
import gallery08 from "../assets/gallery/bx8.jpeg";
import home from "../assets/home.jpg";
import decor from "../assets/sponsors/decor.png";
import euphonious from "../assets/sponsors/euphonious.png";
import ugta from "../assets/sponsors/ugta.png";
import dmd from "../assets/sponsors/dmd.png";
import Dv from "../assets/sponsors/Dv.jpeg";
import fivemadmen from "../assets/sponsors/fivemadmen.png";
import ht from "../assets/sponsors/ht.png";
import jmr from "../assets/sponsors/jmr.png";
import knl from "../assets/sponsors/knl.png";
import patsavv from "../assets/sponsors/patsavv.png";
import pcl from "../assets/sponsors/pcl.png";
import rcd from "../assets/sponsors/rcd.png";
import shlive from "../assets/sponsors/shlive.png";
import cnl from "../assets/sponsors/cnl.png";
import rtr from "../assets/sponsors/rtr.png";

const images = {
  bg,
  beatbox,
  insta,
  fb,
  linkedin,
  kolkata,
  bhopal,
  nagpur,
  vadodara,
  hyderabad,
  varanasi,
  chennai,
  chandigarh,
  delhi,
  pune,
  shillong,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  home,
  decor,
  euphonious,
  ugta,
  dmd,
  Dv,
  fivemadmen,
  ht,
  jmr,
  knl,
  patsavv,
  pcl,
  rcd,
  shlive,
  cnl,
  rtr,
};

export default images;
