
import images from "./images";

const schedule = [
  /*{
    img: images.kolkata,
    city: "All India Prelims",
    date: "Sunday, Jul 31",
    result: "To be announced",
  },*/
  {
    img: images.chandigarh,
    city: "Chandigarh",
    date: "31st July",
    result: "Bladevil DaBeast",
  },
  {
    img: images.bhopal,
    city: "Bhopal",
    date: "14th August",
    result: "Jpunk",
  },
  {
    img: images.kolkata,
    city: "Kolkata",
    date: "9th September",
    result: "Arsalaan Rahim",
  },
  {
    img: images.nagpur,
    city: "Nagpur",
    date: "18th December",
    result: "Sujal Dupare",
  },
  

  /*{
    img: images.hyderabad,
    city: "Hyderabad",
    date: "To be announced",
    result: "To be announced",
  },
  {
    img: images.shillong,
    city: "Mumbai",
    date: "To be announced",
    result: "To be announced",
  },
  {
    img: images.delhi,
    city: "Delhi",
    date: "To be announced",
    result: "To be announced",
  },*/
];

const gallery = [
  {
    img: images.gallery01,
  },
  {
    img: images.gallery02,
  },
  {
    img: images.gallery03,
  },
  {
    img: images.gallery04,
  },
  {
    img: images.gallery05,
  },
];

const contactInfo = [
  {
    id: 1,
    name: "Abhishek Bansod",
    post: "Organizer, Battle Underground",
    image:
      "https://drive.google.com/uc?export=view&id=11FyBdmNd8o3s49j7_VRXoPKYhjsMRBG3",
    facebook: "/",
    phone: "7745840475",
    email: "underground@antaragni.in",
    linkedin: "/",
  },
  {
    id: 2,
    name: "Ishaan Shukla",
    post: "Organizer, Battle Underground",
    image:
      "https://drive.google.com/uc?export=view&id=1tufHssN7_uNPakyMIUYDu_Jfi9QrAPmA",
    facebook: "/",
    phone: "6376735516",
    email: "underground@antaragni.in",
    linkedin: "/",
  },
];

const sponsorData = [
  {
    name: "Casa Decor",
    link: "https://www.instagram.com/casadecorindia/?hl=en",
    img: images.decor,
    post: "Powered By, Delhi Finale",
  },
  {
    name: "Euphonious studios",
    link: "https://www.instagram.com/theeuphoniousstudios/",
    img: images.euphonious,
    post: "Dream on Partner, Delhi Finale",
  },
  {
    name: "Ugta Bharat",
    link: "https://www.ugtabharat.com/",
    img: images.ugta,
    post: "Media Partner, Delhi Finale",
  },
  {
    name: "Dream Art Record",
    link: "https://www.youtube.com/c/DreamArtRecords/",
    img: images.dreamart,
    post: "Official Recording Studio",
  },
  
  {name: "Plague Club and Lounge",
  link: "https://www.instagram.com/playgueclublounge/",
  img: images.pcl,
  post: "Venue Partner, Delhi Finale",
},
{
  name: "Poison Club",
  link: "https://www.facebook.com/poisonchandigarh/",
  img: images.poison,
  post: "Venue Partner, Chandigarh",
},
{
  name: "Rap Club Delhi",
  link: "https://www.instagram.com/rap_club_delhi/",
  img: images.rcd,
  post: "Online Media Partner, Delhi Finale",
},

];

const pastSponsors = [
  {
    name: "Chennai Night Life",
    img: images.cnl,
    link: "https://www.facebook.com/chennainightlife.in/",
    post: "Media Partner, Chennai",
  },
  {
    name: " Raag to Rock",
    img: images.rtr,
    link: "https://www.facebook.com/RagatoRock/",
    post: " Media Partner, Kolkata",
  },
];

const data = {
  schedule,
  gallery,
  contactInfo,
  sponsorData,
  pastSponsors,
};

export default data;
