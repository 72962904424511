import React from "react";
import "./Form.css";
import { getDoc, doc, getFirestore } from "firebase/firestore";
import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firebaseConfig } from "../../../firebaseConfig";
const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore(app);

const db = firestore.collection("rap24");

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const switchValidation = (id, value) => {
  if (value) {
    document.getElementById(id).classList.add("is-valid");
    document.getElementById(id).classList.remove("is-invalid");
  } else {
    document.getElementById(id).classList.add("is-invalid");
    document.getElementById(id).classList.remove("is-valid");
  }
};

const Form = () => {
  const [bandName, setName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [cityName, setCityName] = useState("");
  const [eventName, setEventName] = useState("All India Prelims");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [instaLink, setInstaLink] = useState("");
  // const [moreSongs, setMoreSongs] = useState(null);
  const [facebookLink, setFacebookLink] = useState("");
  // const [otherProfiles, setOtherProfiles] = useState(null);
  const [submissionLinks, setSubmissionLinks] = useState("");

  const [formSubmitted, setSubmitted] = useState(false);
  const [cities, setCities] = useState([]);
  const fetchData = async () => {
    try {
      const citiesDB = getFirestore(app);
      const docRef = doc(citiesDB, "WebContents", "roadtripsCities");
      const docSnap = await getDoc(docRef);
      const rawData = docSnap.data();
      let cities = [];
      for (let i = 0; i < rawData.data.length; i++) {
        if (rawData.data[i].flag === "rap") {
          cities.push(rawData.data[i].city);
        }
      }
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // if (id === "member1") {
    //   setMember1(value);
    //   switchValidation(id, value);
    // }
    // if (id === "member2") {
    //   setMember2(value);
    //   switchValidation(id, value);
    // }
    // if (id === "member3") setMember3(value);
    // if (id === "member4") setMember4(value);
    // if (id === "member5") setMember5(value);
    // if (id === "member6") setMember6(value);
    if (id === "bandName") {
      setName(value);
      switchValidation(id, value);
    }
    if (id === "emailId") {
      setEmailId(value);
      switchValidation(id, validateEmail(value));
    }
    if (id === "cityName") {
      setCityName(value);
      switchValidation(id, value);
    }
    if (id === "eventName") {
      setEventName(value);
      switchValidation(id, value);
    }
    if (id === "contactNumber") {
      setContactNumber(value);
      switchValidation(id, value);
    }
    if (id === "alternateNumber") setAlternateNumber(value);
    if (id === "instaLink") {
      setInstaLink(value);
      switchValidation(id, value);
    }
    if (id === "submissionLinks") setSubmissionLinks(value);
    if (id === "facebookLink") {
      setFacebookLink(value);
      switchValidation(id, value);
    }
  };

  const handleSubmit = () => {
    if (
      bandName &&
      eventName &&
      cityName &&
      validateEmail(emailId) &&
      contactNumber &&
      facebookLink
    ) {
      let obj = {
        name: bandName.toUpperCase(),
        email: emailId,
        homecity: cityName.toUpperCase(),
        event: eventName.toUpperCase(),
        phone: contactNumber,
        altphone: alternateNumber,
        instalink: instaLink,
        // moreSongs: moreSongs,
        fblink: facebookLink,
        submissionlinks: submissionLinks,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      db.doc()
        .set(obj)
        .then(() => {
          setSubmitted(true);
        })
        .catch((error) => {
          console.log(error);
          alert("Something went wrong");
        });
    } else {
      alert("Please fill all the required fields");
    }
  };

  if (formSubmitted) {
    return (
      <div className="app__form app__submitted">
        <Fade cascade bottom>
          <h1 className="h1__submitted">Form Submitted</h1>
        </Fade>
      </div>
    );
  }
  return (
    <div className="app__form">
      <Fade cascade bottom>
        <form>
          <div className="row row-cols-sm-2 row-cols-1">
            {/* BandName */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="bandName"
                placeholder="bandName"
                value={bandName}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="bandName">Name *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Email Id */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="email"
                className="form__input form-control"
                id="emailId"
                placeholder="emailId"
                value={emailId}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="emailId">Email ID *</label>
              <div className="invalid-feedback">Invalid Email Id provided</div>
            </div>

            {/* City Name */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="cityName"
                placeholder="cityName"
                value={cityName}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="cityName">Home City *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Event Name */}
            <div className="form__column form-floating mb-3">
              <select
                className="form-select"
                id="eventName"
                placeholder="eventName"
                value={eventName}
                onChange={(e) => handleInputChange(e)}
                required
              >
                <option value="All India Prelims">All India Prelims</option>
                {cities.map((city) => (
                  <option value={city}>{city}</option>
                ))}
              </select>
              <label for="floatingSelect">Event Name *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>
          </div>

          {/* <div className="row row-cols-md-3 row-cols-1 row-cols-sm-2 ">
     
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member1"
                placeholder="member1"
                value={member1}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="member1">Member 1 *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member2"
                placeholder="member2"
                value={member2}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="member2">Member 2 *</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member3"
                placeholder="member3"
                value={member3}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member3">Member 3</label>
            </div>

            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member4"
                placeholder="member4"
                value={member4}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member4">Member 4</label>
            </div>

         
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member5"
                placeholder="member5"
                value={member5}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member5">Member 5</label>
            </div>

        
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="member6"
                placeholder="member6"
                value={member6}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="member6">Member 6</label>
            </div>
          </div> */}

          {/* <p
            style={{ fontWeight: "700", color: "var(--color-golden)" }}
            className="p__opensans"
          >
            ** If there are more than 6 members, write comma separated names **
          </p> */}

          <div className="row row-cols-sm-2 row-cols-1">
            {/* Contact Number */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="number"
                className="form__input form-control"
                id="contactNumber"
                placeholder="contactNumber"
                value={contactNumber}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="contactNumber">Contact Number*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Alternate Number */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="number"
                className="form__input form-control"
                id="alternateNumber"
                placeholder="alternateNumber"
                value={alternateNumber}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="alternateNumber">Alternate Number</label>
            </div>

            {/* Song Link */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="url"
                className="form__input form-control"
                id="instaLink"
                placeholder="instaLink"
                value={instaLink}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="songLink">Instagram Link*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="text"
                className="form__input form-control"
                id="moreSongs"
                placeholder="moreSongs"
                value={moreSongs}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="moreSongs">More Song Links</label>
            </div> */}

            {/* FaceBook Link */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="url"
                className="form__input form-control"
                id="facebookLink"
                placeholder="facebookLink"
                value={facebookLink}
                onChange={(e) => handleInputChange(e)}
                required
              />
              <label for="facebookLink">Facebook Profile Link*</label>
              <div className="invalid-feedback">Required Field</div>
            </div>

            {/* Other Profile Links */}
            <div className="form__column form-floating mb-3">
              <input
                autoComplete="off"
                type="url"
                className="form__input form-control"
                id="submissionLinks"
                placeholder="submissionLinks"
                value={submissionLinks}
                onChange={(e) => handleInputChange(e)}
              />
              <label for="submissionLinks">Submission Link</label>
            </div>
          </div>

          <button
            onClick={() => handleSubmit()}
            className="btn btn-light"
            type="button"
          >
            Submit Form
          </button>
        </form>
      </Fade>
    </div>
  );
};

export default Form;
